import React from 'react'
import cardStyles from './card.module.css'

export default class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDownload: false,
    }
  }

  toggleDownload() {
    this.setState({
      showDownload: !this.state.showDownload,
    })
  }

  render() {
    return (
      <div className={cardStyles.panel}>
        <div className={cardStyles.title}>
          <div>{this.props.title}</div>
        </div>
        <div className={cardStyles.tags}>
          {this.props.version && <span className={cardStyles.tag}>版本: {this.props.version}</span>}
          {this.props.time && <span className={cardStyles.tag}>更新时间: {this.props.time}</span>}
        </div>
        <div className={cardStyles.tags}>
          <span className={cardStyles.tag} style={{color: 'green'}}>适合平台: {this.props.platforms.join(', ')}</span>
        </div>
        <div className={cardStyles.content}>{this.props.content}</div>
        <div className={cardStyles.separator}/>
        <div className={cardStyles.handle}>
          <a href={this.props.website} rel="noopener noreferrer" target='_blank' className={cardStyles.btn}>网站</a>
          <div className={`${cardStyles.btn} ${cardStyles.ml}`} onClick={this.toggleDownload.bind(this)}>
            {this.state.showDownload ? '隐藏下载' : '查看下载'}
          </div>
        </div>
        {this.state.showDownload && <div className={cardStyles.subpanel}>
          <div>
            <div className={cardStyles.tip}>请选择一个地址进行下载:</div>
            <div className={cardStyles.f}>
              {this.props.downloads.map((e, i) => (
                <a key={i} href={e.url} rel="noopener noreferrer" target='_blank' className={cardStyles.btn}>{e.name}</a>
              ))}
            </div>
          </div>
        </div>}
      </div>
    )
  }
}