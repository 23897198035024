import React from 'react'

import Layout from '../components/layout'
import Card from '../components/card'

import indexStyles from './index.module.css'

let pcs = [{
  id: 1,
  website: 'http://mc.163.com/index.html',
  title: '中文官方启动器',
  content: '网易启动器,免费游戏(网易是我的世界在中国的官方合作伙伴)',
  version: '1.3.0.47160',
  time: '2018-10-26',
  platforms: ['Windows'],
  downloads: [{
    name: '直接下载',
    url: 'http://x19.gdl.netease.com/mclauncher_1.3.0.47160.exe',
  }, {
    name: 'adfly',
    url: 'http://streamvoyage.com/3YGV',
  }, {
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/1n-K5USar-zLcz4Eqt0jImA',
  }]
},{
  id: 2,
  website: 'https://minecraft.net',
  title: '英文官方启动器',
  content: '英文版的官方启动器,需要购买游戏才能使用(又叫Minecraft Launcher)',
  platforms: ['Windows', 'Mac', 'Linux'],
  downloads: [{
    name: '官方',
    url: 'https://minecraft.net/zh-hans/download',
  }]
},{
  id: 6,
  website: 'https://hmcl.huangyuhui.net/',
  title: 'HMCL启动器',
  content: 'Hello Minecraft! Launcher',
  version: '3',
  time: '2018-10-26',
  platforms: ['Windows', 'Mac', 'Linux'],
  downloads: [{
    name: '官方',
    url: 'https://hmcl.huangyuhui.net/download',
  },{
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/18riu3bSouwxzwyvs7gi_sQ',
  }]
},{
  id: 7,
  website: 'https://www.bangbang93.com/category/5/bmcl',
  title: 'BMCL启动器',
  content: 'bangbang93\'s Minecraft Launcher',
  version: '4.7.0',
  time: '2018-10-26',
  platforms: ['Windows'],
  downloads: [{
    name: '直接下载',
    url: 'https://www.bangbang93.com/assets/uploads/files/1509025948978-bmclng.exe',
  },{
    name: 'adfly',
    url: 'http://streamvoyage.com/3a9N',
  },{
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/1qWh_qy7C4HhTQxxtH0vNWQ',
  }]
},{
  id: 8,
  website: 'http://www.bakaxl.com/',
  title: 'BakaXL启动器',
  content: '脑子有洞的新一代Minecraft崩坏启动器',
  version: '2.2.8',
  time: '2018-10-26',
  platforms: ['Windows'],
  downloads: [{
    name: '直接下载',
    url: 'http://spigot.x-speed.cc/tt-cdn/BakaXL/Release/BakaXL_Public_Ver_2.2.8.2.exe',
  },{
    name: 'adfly',
    url: 'http://streamvoyage.com/3acz',
  },{
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/1sBKXESahWm5kA4fcR5b98g',
  }]
},{
  id: 3,
  website: 'http://mcbox.duowan.com/pc.html',
  title: '多玩盒子',
  content: '多玩我的世界盒子(又称炸妈盒子)',
  version: '10.1',
  time: '2018-10-26',
  platforms: ['Windows'],
  downloads: [{
    name: '直接下载',
    url: 'http://pkg.tuboshu.com/common/pc/mcpcboxsetup_10_1.exe',
  }, {
    name: 'adfly',
    url: 'http://streamvoyage.com/3YpU',
  }, {
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/1-5FYoHTPMSQsgAMM3O6QAA',
  }]
},{
  id: 4,
  website: 'http://www.fkhezi.com/',
  title: '方块盒子',
  content: '我的世界方块盒子',
  version: '1.3.8.0',
  time: '2018-10-26',
  platforms: ['Windows'],
  downloads: [{
    name: '直接下载',
    url: 'http://launcher.zuimc.com/d.php',
  }, {
    name: 'adfly',
    url: 'http://streamvoyage.com/3Z4I',
  }, {
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/1-1GWG42HRKiPndNPMoFRPw',
  }]
},{
  id: 5,
  website: 'http://www.zhai8.com.cn/',
  title: '宅霸平台',
  content: '宅霸联机平台(包含我的世界与其它游戏)',
  version: '4.5.0',
  time: '2018-10-26',
  platforms: ['Windows'],
  downloads: [{
    name: '直接下载',
    url: 'http://download.zhai8.com.cn/down/Zhaiba-4.5.0.exe',
  }, {
    name: 'adfly',
    url: 'http://streamvoyage.com/3ZWa',
  }, {
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/1XqIgqegyXhVzLGTEBfiDzw',
  }]
}]

let pes = [{
  id: 1,
  website: 'https://mc.163.com/',
  title: '安卓版',
  content: '我的世界中国PE版',
  version: '1.8.0',
  time: '2018-10-26',
  platforms: ['安卓'],
  downloads: [{
    name: '直接下载',
    url: 'https://g79.gdl.netease.com/mcpe-release_20180813_1.8-1.8.0.47903-Publish-20180919182712_netease_23_x19_840047903_20180919_191154.apk',
  }, {
    name: 'adfly',
    url: 'http://streamvoyage.com/3b7h',
  }, {
    name: '百度网盘',
    url: 'https://pan.baidu.com/s/1hmWSH2OphFK3oB_ZTogFiw',
  }]
},{
  id: 2,
  website: 'https://mc.163.com/',
  title: 'IOS版',
  content: '我的世界中国PE版',
  version: '1.8.3',
  time: '2018-10-26',
  platforms: ['IOS'],
  downloads: [{
    name: 'App Store',
    url: 'https://itunes.apple.com/cn/app/%E6%88%91%E7%9A%84%E4%B8%96%E7%95%8C/id1243986797?mt=8',
  }]
}]

const IndexPage = () => (
  <Layout>
    <h1>电脑版(PC版)</h1>
    <div className={indexStyles.body}>
      {pcs.map(e => (
        <Card key={e.id} {...e}/>
      ))}
    </div>
    <h1></h1>
    <h1>手机版(PE版)</h1>
    <div className={indexStyles.body}>
      {pes.map(e => (
        <Card key={e.id} {...e}/>
      ))}
    </div>
    <div className={indexStyles.copyright}>©2018 空叶我的世界下载</div>
  </Layout>
)

export default IndexPage
